import React, { useState } from "react";
import { Link } from "react-router-dom";
// import VideoModal from '../../Common/videoModalW';

import "../../Assets/Styles/styles.scss";

const handler = {};

const Underpinning = () => {

  const [isOpen, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false); 
  };

  return (
    <section className="about-section-2">
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          paddingBottom: "0px",
          maxWidth: "980px",
          margin: "0 auto",
        }}
      >
       
        <section style={{ paddingBottom: "20px", marginTop: '70px' }} id="process">
          <div className="container">
            <h3 style={{ marginBottom: "0", paddingBottom: "0", textAlign: 'center' }}>
            BASEMENT WATERPROOFING & UNDERPINNING SOLUTIONS
            </h3><br/>
          </div>
       
        </section>

      
          <p>
            Are you tired of dealing with a wet basement? At AGM Renovations, we specialize in
            basement waterproofing solutions that stand the test of time. Whether you&#39;re facing a damp
            basement, foundation cracks, or basement leaks, our experienced team has the expertise to
            provide effective solutions tailored to your needs.  With our industry-leading waterproofing products and lifetime warranty guarantee, you can
            trust us to keep your basement dry and protected from water damage. Say goodbye to
            basement floods and hello to peace of mind with AGM Renovations.
          </p>

        <br/>
        <br/><br/>
        <section style={{ paddingBottom: "20px" }} id="process">
            <div className="content">
              <h3
                style={{
                  textAlign: "center",
                  margin: "0 auto",
                  fontSize: "2em",
                  paddingBottom: "0",
                }}
              >
                Services:
              </h3>
            </div>
        </section>

        <ul style={{ fontSize: "20px" }}>
          <li>
            <b>Exterior Waterproofing - External Solution:</b> - Our exterior waterproofing solution repairs your leaky basement from the outside,
                creating a foundation waterproofing system that redirects water away from your home.
                This effective solution is ideal for fixing basement leaks and preventing future water
                intrusion.
          </li>
          <br />
          <li>
            <b>Interior Basement Waterproofing - Internal Solution:</b> – Interior waterproofing repairs basement leaks from the inside of your home, preventing
                the growth of mold and mildew. This solution helps alleviate hydrostatic pressure and
                keeps your basement dry and comfortable.
          </li>
          <br />
          <li>
            <b>Basement Lowering/Underpinning:</b> – Looking to expand your living space? Our basement lowering services provide higher
            ceilings and more living space for your family. Underpinning is a great way to increase
            the value of your property while creating a comfortable environment in your basement.
          </li>
          <br />
          <li>
            <b>Waterproofing Foundation Cracks:</b> – Foundation cracks can lead to a wet basement if left untreated. Our epoxy crack
            injection repairs foundation cracks and seals your foundation wall from further water
            damage, ensuring a dry and stable foundation.
          </li>
          <br />
          <li>
            <b>Foundation Structural Repair:</b> – Structural foundation cracks require professional attention to prevent further damage.
            Our experts will assess and repair structural cracks, ensuring the stability and safety of
            your home&#39;s foundation.
          </li>
          <br />
          <li>
            <b>Sump Pump Services:</b> – Our sump pump installations ensure efficient water removal from your basement,
                preventing flooding and water damage. With options for backup battery systems, you
                can trust our sump pumps to keep your basement dry even during power outages.
          </li>
          <br />
          <li>
            <b>Flood Restoration Services:</b> – We offer comprehensive flood restoration services to address
                basement flooding, water damage, mold removal, and sewage cleanup. Our experienced team
                uses state-of-the-art equipment to quickly and effectively restore your property to its pre-
                damage condition.
          </li>
          {/* <br />
          <li>
            <b>A CHANCE TO WIN $30,000</b> – We at AGM pride ourselves in offering our customers the BIGGEST AND
          BEST DEALS. Now, we are offering the biggest deal ever!
          <br />
          <Link style={{ color: '#0025ff', fontSize: '0.9em', borderBottom: '1px solid #0b25ff' }} to="/promotion-agm" >
            Click here for details
          </Link>
          </li> */}

        </ul>
        <br/>
        {/* <div>
            <Link style={{ color: '#0025ff', fontSize: '0.9em',
              color: 'rgb(6, 174, 222)',
              fontSize: '1.2em',
              fontWeight: '600',
              lineHeight: '32px',
              borderBottom: '2px solid rgb(6, 174, 222)'
          }} to="/promotion-agm" >
              Holiday Cash Giveaway - A CHANCE TO WIN $20,000
            </Link>
          </div>
          <br/> */}

        <div
          id="agm-difference-top"
          style={{ padding: "0px", margin: "20px", marginBottom: "40px", textAlign: 'center' }}
        >
          <p>
            Don't let a wet basement ruin your home. Contact AGM Renovations today for reliable
            waterproofing solutions and flood restoration services. With our commitment to quality and
            customer satisfaction, we are here to protect your home and your family.
          </p>
        </div>


        {/* <section>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingBottom: "0px",
              maxWidth: "980px",
              margin: "0 auto",
              textAlign: 'center',
              fontSize: '1.3em',
              lineHeight: '1.5em',
              margin: '60px auto',
            }}
          >
          
              <b>Summer Cash Giveaway Winners</b>
              <br />
              <span style={{ color: 'rgb(6 174 222)', fontWeight: '600', fontSize: '0.9em', cursor: 'pointer', borderBottom: '2px solid rgb(6 174 222)' }} 
                onClick={() => { setOpen(true); }}
              >
                Click here for details
              </span> 
         
          </div>
      </section> */}

      {/* <VideoModal 
        videoId={'GAatDIk9fwc'}
        isOpen={isOpen}
        handleClose={() => { handleClose(); }}
      /> */}
        
            <div className="get-quote-btn" style={{ marginTop: "2rem" }}>
              <Link to="/estimate" className="btn red-btn-2 hvr-sweep-to-right">
              Contact Us Today
              </Link>
            </div>

      </div>
    </section>
  );
};

export default Underpinning;
